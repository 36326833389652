import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { MenuPermisao, compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService,
        private _userService: UserService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() =>
            {
                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) =>
                {
                    this._defaultNavigation.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) =>
                {
                    this._defaultNavigation.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) =>
                {
                    this._defaultNavigation.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                //Fill the menu permissions

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this.filterPermissions(this._compactNavigation)),
                        default   : cloneDeep(this.filterPermissions(this._defaultNavigation)),
                        futuristic: cloneDeep(this.filterPermissions(this._futuristicNavigation)),
                        horizontal: cloneDeep(this.filterPermissions(this._horizontalNavigation)),
                    },
                ];
            });
    }

    private filterPermissions(menu: FuseNavigationItem[]): FuseNavigationItem[] {

        let user = JSON.parse(sessionStorage.getItem('usuario')) as User;

        menu.forEach((item, index) => {

            if(item.children && item.children.length > 0) {
                this.filterPermissions(item.children);
            }

            let find = MenuPermisao.filter(m => m.menuId == item.id &&
                                        m.perfil.codigo  == user.Perfil.codigo &&
                                        m.perfil.tipo.codigo == user.Tipo).length > 0;

            if(!find) {
                menu.splice(index, 1)
            }

        });

        return menu;
    }

}
