export const environment = {
    production: false,
    gdt: {
        home: 'gdt/home.html',
    },
    api: {
        mensagens: {
            resumo: 'api/mensagem/consultarResumoMensagensPendentes',
        },
        terceiro: {
            documentosPendentes:
                'api/terceiro/consultarTotalDocumentosPendentes',
        },
        documentos: {
            tiposdocumentos: 'api/tipodocumento/consultar',
        },
    },
};
