/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import {
    PerfilMenu,
    PermissaoMenu,
} from 'app/core/navigation/permission.types';

const CompactMenu: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Início',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home',
        tooltip: "Home"
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboard',
        tooltip: "Dashboard"
    },
    {
        id: 'crud.cadastros',
        title: 'Cadastros',
        subtitle: '',
        type: 'aside',
        icon: 'heroicons_outline:rectangle-group',
        tooltip: "Cadastros",
        children: [],
    },
    {
        id: 'gestao',
        title: 'Gestão',
        type: 'aside',
        icon: 'heroicons_outline:briefcase',
        link: '/example',
    },
    {
        id: 'relatorios',
        title: 'Relatórios',
        type: 'aside',
        icon: 'heroicons_outline:arrow-trending-up',
        tooltip: 'Relatórios',
        children: [],
    },
    {
        id: 'qrcode',
        title: 'Qrcode',
        type: 'basic',
        icon: 'heroicons_outline:qr-code',
        tooltip: 'Qrcode',
        link: '/example',
    },
];

const Menu: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home',
        tooltip: "Home"
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboard',
        tooltip: "Dashboard"
    },
    {
        id: 'crud.cadastros',
        title: 'Cadastros',
        type: 'collapsable',
        icon: 'heroicons_outline:rectangle-group',
        children: [
            {
                id: 'crud.documentos',
                title: 'Documentos',
                type: 'collapsable',
                icon: 'heroicons_outline:document-duplicate',
                children: [
                    {
                        id: 'crud.documentos.tipodocumento',
                        title: 'Tipo de Documentos',
                        type: 'basic',
                        icon: 'heroicons_outline:document-text',
                        link: '/documentacao/tipo-documento',
                    },
                    {
                        id: 'crud.documentos.documentacao',
                        title: 'Documentação',
                        type: 'basic',
                        icon: 'heroicons_outline:document-plus',
                        link: '/gdt/cadastro/documentos',
                    },
                    {
                        id: 'crud.documentos.empresa',
                        title: 'Documentos da Empresa',
                        type: 'basic',
                        icon: 'heroicons_outline:document-text',
                        link: '/gdt/cadastro/documentos-empresa',
                    },
                    {
                        id: 'crud.documentos.funcionario',
                        title: 'Documentos do Funcionário',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-document',
                        link: '/documentacao/documentos-funcionario',
                    },
                ]
            },
            {
                id: 'crud.terceiro',
                title: 'Terceiros',
                type: 'basic',
                icon: 'heroicons_outline:building-office-2',
                link: '/gdt/cadastro/terceiros',
            },
            {
                id: 'crud.filiais',
                title: 'Filiais',
                type: 'basic',
                icon: 'heroicons_outline:building-office',
                link: '/gdt/cadastro/filiais',
            },
            {
                id: 'crud.setor',
                title: 'Setor',
                type: 'basic',
                icon: 'heroicons_outline:home-modern',
                link: '/gdt/cadastro/setor',
            },
            {
                id: 'crud.categorias',
                title: 'Categorias',
                type: 'basic',
                icon: 'heroicons_outline:bookmark',
                link: '/gdt/cadastro/categorias',
            },
            {
                id: 'crud.usuario',
                title: 'Usuários',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/gdt/cadastro/usuarios',
            },
            {
                id: 'crud.nr',
                title: 'NR',
                type: 'basic',
                icon: 'heroicons_outline:document-chart-bar',
                link: '/gdt/cadastro/nrs',
            },
            {
                id: 'crud.funcionario',
                title: 'Funcionários',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/gdt/cadastro/funcionarios',
            },
        ]
    },
    {
        id: 'gestao',
        title: 'Gestão',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:briefcase',
        children: [
            {
                id: 'gestao.aprovacoes',
                title: 'Aprovações',
                type: 'basic',
                icon: 'heroicons_outline:document-check',
                link: '/gdt/gestao/aprovacoes',
            },
            {
                id: 'gestao.arquivos',
                title: 'Arquivos',
                type: 'basic',
                icon: 'heroicons_outline:document-check',
                link: '/gdt/gestao',
            },
            {
                id: 'gestao.repositorio',
                title: 'Repositório de Documentos',
                type: 'basic',
                icon: 'heroicons_outline:archive-box',
                link: '/gdt/gestao/repositorio-documentos',
            },
            {
                id: 'gestao.cat',
                title: 'Ocorrencia de Saúde e Segurança',
                type: 'basic',
                icon: 'heroicons_outline:exclamation-triangle',
                link: '/gdt/gestao/ocorrencia-saude-seguranca',
            },
            {
                id: 'gestao.integracoes',
                title: 'Integrações',
                type: 'basic',
                icon: 'heroicons_outline:arrow-path-rounded-square',
                link: '/gdt/gestao/integracoes',
            },
            {
                id: 'gestao.consultas',
                title: 'Consultas',
                type: 'basic',
                icon: 'heroicons_outline:magnifying-glass',
                link: '/gdt/gestao/consultas',
            },
            {
                id: 'gestao.configuracoes',
                title: 'Configurações',
                type: 'basic',
                icon: 'heroicons_outline:cog-8-tooth',
                link: '/gdt/gestao/configuracoes',
            },
        ],
    },
    {
        id: 'relatorios',
        title: 'Relatórios',
        type: 'collapsable',
        icon: 'heroicons_outline:arrow-trending-up',
        children: [
            {
                id: 'relatorio.documento.empresa',
                title: 'Documentação de Empresa',
                icon: 'heroicons_outline:document-text',
                type: 'basic',
                link: '/gdt/relatorio/documentacao-empresa',
            },
            {
                id: 'relatorio.documento.funcionario',
                title: 'Documentação de Funcionário',
                icon: 'heroicons_outline:clipboard-document',
                type: 'basic',
                link: '/gdt/relatorio/documentacao-funcionario',
            },
            {
                id: 'relatorio.empresas',
                icon: 'heroicons_outline:building-office-2',
                title: 'Empresas',
                type: 'basic',
                link: '/gdt/relatorio/empresas',
            },
            {
                id: 'relatorio.funcionarios',
                title: 'Funcionários',
                icon: 'heroicons_outline:user-group',
                type: 'basic',
                link: '/gdt/relatorio/funcionarios',
            },
            {
                id: 'relatorio.usuarios',
                title: 'Usuários',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/gdt/relatorio/usuarios',
            },
            {
                id: 'relatorio.nrs',
                title: 'NR´s',
                type: 'basic',
                icon: 'heroicons_outline:document-chart-bar',
                link: '/gdt/relatorio/nrs',
            },
            {
                id: 'relatorio.vencimentos.contratos',
                title: 'Vencimentos de Contratos',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/gdt/relatorio/vencimento-contratos',
            },
            {
                id: 'relatorio.ocs',
                title: 'OC´s',
                type: 'basic',
                icon: 'heroicons_outline:receipt-percent',
                link: '/gdt/relatorio/oc',
            },
            {
                id: 'relatorio.visao.geral.funcionarios',
                title: 'Visão Geral de Funcionários',
                type: 'basic',
                icon: 'heroicons_outline:table-cells',
                link: '/gdt/relatorio/visao-geral-funcionarios',
            },
            {
                id: 'relatorio.ocorrencia.saude.seguranca',
                title: 'Ocorrências Saúde e Segurança',
                type: 'basic',
                icon: 'heroicons_outline:exclamation-circle',
                link: '/gdt/relatorio/ocorrencia-saude-seguranca',
            },
        ],
    },
    {
        id: 'qrcode',
        title: 'Qrcode',
        type: 'basic',
        icon: 'heroicons_outline:qr-code',
        link: '/gdt/qrcode',
    },
];

export const defaultNavigation: FuseNavigationItem[] = Menu;

export const compactNavigation: FuseNavigationItem[] = CompactMenu;

export const futuristicNavigation: FuseNavigationItem[] = Menu;

export const horizontalNavigation: FuseNavigationItem[] = Menu;

const PerfilAdm: PerfilMenu = { codigo: 1 , tipo: { codigo: 1 } };
const PerfilUsuario: PerfilMenu = { codigo: 2, tipo: { codigo: 1 } };
const PerfilUsuarioTerceiro: PerfilMenu = { codigo: 2, tipo: { codigo: 2 } };
const PerfilMaster: PerfilMenu = { codigo:  3, tipo: {codigo : 1} };
const PerfilSuprimentos: PerfilMenu = { codigo: 4, tipo: {codigo : 1} };
const PerfilSeguranca: PerfilMenu = { codigo:  5, tipo: {codigo : 1} };

export const MenuPermisao: PermissaoMenu[] = [

    //Admin
    { menuId: 'home', perfil: PerfilAdm },
    { menuId: 'inicio', perfil: PerfilAdm },
    { menuId: 'dashboard', perfil: PerfilAdm },
    { menuId: 'crud', perfil: PerfilAdm },
    { menuId: 'crud.cadastros', perfil: PerfilAdm },
    { menuId: 'crud.documentos', perfil: PerfilAdm },
    { menuId: 'crud.documentos.documentacao', perfil: PerfilAdm },
    // { menuId: 'crud.documentos.tipodocumento', perfil: PerfilAdm },
    // { menuId: 'crud.documentos.empresa', perfil: PerfilAdm },
    // { menuId: 'crud.documentos.funcionario', perfil: PerfilAdm },
    { menuId: 'crud.terceiro', perfil: PerfilAdm },
    { menuId: 'crud.filiais', perfil: PerfilAdm },
    { menuId: 'crud.setor', perfil: PerfilAdm },
    { menuId: 'crud.categorias', perfil: PerfilAdm },
    { menuId: 'crud.usuario', perfil: PerfilAdm },
    { menuId: 'crud.nr', perfil: PerfilAdm },
    { menuId: 'crud.funcionario', perfil: PerfilAdm },
    { menuId: 'gestao', perfil: PerfilAdm },
    { menuId: 'gestao.aprovacoes', perfil: PerfilAdm },
    { menuId: 'gestao.repositorio', perfil: PerfilAdm },
    { menuId: 'gestao.cat', perfil: PerfilAdm },
    { menuId: 'gestao.integracoes', perfil: PerfilAdm },
    { menuId: 'gestao.consultas', perfil: PerfilAdm },
    { menuId: 'gestao.configuracoes', perfil: PerfilAdm },
    { menuId: 'relatorios', perfil: PerfilAdm },
    { menuId: 'relatorio.documento.empresa', perfil: PerfilAdm },
    { menuId: 'relatorio.documento.funcionario', perfil: PerfilAdm },
    { menuId: 'relatorio.empresas', perfil: PerfilAdm },
    { menuId: 'relatorio.funcionarios', perfil: PerfilAdm },
    { menuId: 'relatorio.usuarios', perfil: PerfilAdm },
    { menuId: 'relatorio.nrs', perfil: PerfilAdm },
    { menuId: 'relatorio.vencimentos.contratos', perfil: PerfilAdm },
    { menuId: 'relatorio.ocs', perfil: PerfilAdm },
    { menuId: 'relatorio.visao.geral.funcionarios', perfil: PerfilAdm },
    { menuId: 'relatorio.ocorrencia.saude.seguranca', perfil: PerfilAdm },
    { menuId: 'qrcode', perfil: PerfilAdm },

    //Usuario Interno
    { menuId: 'home', perfil: PerfilUsuario },
    { menuId: 'inicio', perfil: PerfilUsuario },
    { menuId: 'dashboard', perfil: PerfilUsuario },
    { menuId: 'crud', perfil: PerfilUsuario },
    { menuId: 'crud.cadastros', perfil: PerfilUsuario },
    { menuId: 'crud.terceiro', perfil: PerfilUsuario },
    { menuId: 'crud.usuario', perfil: PerfilUsuario },
    { menuId: 'crud.funcionario', perfil: PerfilUsuario },
    { menuId: 'gestao', perfil: PerfilUsuario },
    { menuId: 'gestao.aprovacoes', perfil: PerfilUsuario },
    { menuId: 'gestao.repositorio', perfil: PerfilUsuario },
    { menuId: 'gestao.cat', perfil: PerfilUsuario },
    { menuId: 'gestao.integracoes', perfil: PerfilUsuario },
    { menuId: 'gestao.consultas', perfil: PerfilUsuario },
    { menuId: 'relatorios', perfil: PerfilUsuario },
    { menuId: 'relatorio.documento.empresa', perfil: PerfilUsuario },
    { menuId: 'relatorio.documento.funcionario', perfil: PerfilUsuario },
    { menuId: 'relatorio.empresas', perfil: PerfilUsuario },
    { menuId: 'relatorio.funcionarios', perfil: PerfilUsuario },
    { menuId: 'relatorio.usuarios', perfil: PerfilUsuario },
    { menuId: 'relatorio.nrs', perfil: PerfilUsuario },
    { menuId: 'relatorio.vencimentos.contratos', perfil: PerfilUsuario },
    { menuId: 'relatorio.ocs', perfil: PerfilUsuario },
    { menuId: 'relatorio.visao.geral.funcionarios', perfil: PerfilUsuario },
    { menuId: 'relatorio.ocorrencia.saude.seguranca', perfil: PerfilUsuario },
    { menuId: 'qrcode', perfil: PerfilUsuario },

    //Usuario Terceiro
    { menuId: 'home', perfil: PerfilUsuarioTerceiro },
    { menuId: 'inicio', perfil: PerfilUsuarioTerceiro },
    { menuId: 'dashboard', perfil: PerfilUsuarioTerceiro },
    { menuId: 'crud', perfil: PerfilUsuarioTerceiro },
    { menuId: 'crud.cadastros', perfil: PerfilUsuarioTerceiro },
    { menuId: 'crud.terceiro', perfil: PerfilUsuarioTerceiro },
    { menuId: 'crud.funcionario', perfil: PerfilUsuarioTerceiro },
    { menuId: 'gestao', perfil: PerfilUsuarioTerceiro },
    { menuId: 'gestao.arquivos', perfil: PerfilUsuarioTerceiro },
    { menuId: 'gestao.repositorio', perfil: PerfilUsuarioTerceiro },
    { menuId: 'gestao.cat', perfil: PerfilUsuarioTerceiro },
    { menuId: 'relatorios', perfil: PerfilUsuarioTerceiro },
    { menuId: 'relatorio.visao.geral.funcionarios', perfil: PerfilUsuarioTerceiro },

    //Master
    { menuId: 'home', perfil: PerfilMaster },
    { menuId: 'inicio', perfil: PerfilMaster },
    { menuId: 'crud', perfil: PerfilMaster },
    { menuId: 'crud.cadastros', perfil: PerfilMaster },
    { menuId: 'crud.terceiro', perfil: PerfilMaster },
    { menuId: 'crud.filiais', perfil: PerfilMaster },
    { menuId: 'crud.setor', perfil: PerfilMaster },
    { menuId: 'crud.categorias', perfil: PerfilMaster },
    { menuId: 'crud.usuario', perfil: PerfilMaster },
    { menuId: 'gestao', perfil: PerfilMaster },
    { menuId: 'gestao.integracoes', perfil: PerfilMaster },

    //Master
    { menuId: 'home', perfil: PerfilMaster },
    { menuId: 'inicio', perfil: PerfilMaster },
    { menuId: 'crud', perfil: PerfilMaster },
    { menuId: 'crud.cadastros', perfil: PerfilMaster },
    { menuId: 'crud.terceiro', perfil: PerfilMaster },
    { menuId: 'crud.filiais', perfil: PerfilMaster },
    { menuId: 'crud.setor', perfil: PerfilMaster },
    { menuId: 'crud.categorias', perfil: PerfilMaster },
    { menuId: 'crud.usuario', perfil: PerfilMaster },
    { menuId: 'gestao', perfil: PerfilMaster },
    { menuId: 'gestao.integracoes', perfil: PerfilMaster },

    //Suprimentos
    { menuId: 'home', perfil: PerfilSuprimentos },
    { menuId: 'inicio', perfil: PerfilSuprimentos },
    { menuId: 'crud', perfil: PerfilSuprimentos },
    { menuId: 'crud.cadastros', perfil: PerfilSuprimentos },
    { menuId: 'crud.terceiro', perfil: PerfilSuprimentos },
    { menuId: 'gestao.repositorio', perfil: PerfilUsuarioTerceiro },
    { menuId: 'gestao.cat', perfil: PerfilUsuarioTerceiro },

    //Segurança
    { menuId: 'home', perfil: PerfilSeguranca },
    { menuId: 'inicio', perfil: PerfilSeguranca },
    { menuId: 'crud', perfil: PerfilSeguranca },
    { menuId: 'crud.cadastros', perfil: PerfilSeguranca },
    { menuId: 'crud.terceiro', perfil: PerfilSeguranca },
    { menuId: 'crud.funcionario', perfil: PerfilUsuarioTerceiro },
    { menuId: 'relatorios', perfil: PerfilUsuarioTerceiro },
    { menuId: 'relatorio.visao.geral.funcionarios', perfil: PerfilUsuarioTerceiro }

];
